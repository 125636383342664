<template>
  <div class="settings">
    <loader v-if="!pageLoaded"/>
    <div v-else style="width: 100%;margin-bottom: 300px;padding: 0; margin-left: 57px; margin-top:30px;z-index: 2;">

      <div style="display: flex; justify-content: space-between">
        <p @click="routeBack('/panel/ilanlar',false)" class="cuper-black-button" style="width: 113px; height: 46px; cursor: pointer">
          Geri Dön
        </p>
        <p @click="deleteGig" class="delete-gig">
          Bu İlanı Sil
        </p>
      </div>

      <div  class="finance-info for-seller">
        <div style="margin-left: 20px">
          <img src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="for-seller-icon"/>
        </div>
        <div style="margin-left: 15px">
          <div class="description">
            <p class="disapproval-row">Seni dinledik! Artık minimum ilan & sipariş tutarı 100 TL! Fiyatı güncellenmeyen ilanlar 9 Nisan 2023 tarihinden başlayarak kademeli olarak yayından kaldırılacak.</p>
          </div>
        </div>
      </div>

      <div v-if="rerender" style="margin-top: 50px;">


        <div v-if="warning.displayWarning && warning.disapprovals.length" class="request-warning">
          <div class="request-warning-inside">
            <div>
              <img src="https://gcdn.bionluk.com/site/cicons/ic-warning.svg"  onload="SVGInject(this)" class="warning-icon">
            </div>
            <div style="margin-left: 15px;">
              <p class="warning-head">{{warning.warningHeader}}</p>
              <p class="warning-sub-head">{{warning.warningText}}</p>

              <p class="disapproval-row" v-for="disapproval in warning.disapprovals">
                <span v-if="disapproval.disapprovalTitle !== 'Editörümüzün Mesajı:'" class="warning-description-html" >{{disapproval.disapprovalDescription}}</span>
                <span v-if="disapproval.disapprovalTitle && disapproval.disapprovalTitle === 'Editörümüzün Mesajı:'" class="warning-description-html" v-html="'Editörümüzün Mesajı: ' + disapproval.disapprovalDescription"></span>
                <router-link class="link" target="_blank" v-if="disapproval.disapprovalSupportLink" :to="disapproval.disapprovalSupportLink">[ayrıntılı bilgi]</router-link>
              </p>

            </div>
          </div>
        </div>


        <div style="margin-bottom: 0;">
          <div style="display: inline-block">
            <label>Kategori Seçimi</label>
            <select v-model="selectedCatId" class="super-drop-c"  :style="selectedCatId && gig.gig_category && selectedCatId !== gig.gig_category.id ? 'border: solid 1px #00a575;color:#2d3640' : !selectedCatId ? '' : 'border: solid 1px #2d3640;color:#2d3640'">
              <option disabled :value="null">Kategori Seç</option>
              <option v-for="category in parentCategories" :value="category.id">{{ category.name }}</option>
            </select>
          </div>

          <div style="display: inline-block; margin-left: 30px;"> - </div>
          <div style="display: inline-block; margin-left: 30px;">
            <label></label>
            <select v-model="selectedSubCatId" :disabled="!selectedCat.id" class="super-drop-c" :style="selectedSubCatId && gig.gig_category_sub && selectedSubCatId !== gig.gig_category_sub.id ? 'border: solid 1px #00a575;color:#2d3640' : !selectedSubCatId ? '' : 'border: solid 1px #2d3640;color:#2d3640'">
              <option disabled :value="null">Kategori Seç</option>
              <option  v-for="(subCategory, index) in categories"  :key="index+300" v-if="selectedCatId && (selectedCatId === subCategory.parent_id)" :value="subCategory.id">
                {{ subCategory.name }}
              </option>
            </select>
          </div>
        </div>



        <div v-if="showStep2TypeArea" id="sub-cat-type-kind-container" class="sub-cat-type-kind-container">
          <p class="title">Hizmet Türü</p>
          <div style="display: flex; align-items: flex-start; margin-top: 10px">
            <img style="margin-top: 7px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
            <p class="help-text" ><span>{{selectedSubCat.name}}</span> kategorisinde vereceğin hizmet türü hangisi?</p>
          </div>

          <div class="sub-cat-box-container" style="margin-top: 30px">
            <select class="super-drop-c" v-model="selectedServiceType" :style="selectedServiceType && gig.gig_service_types && gig.gig_service_types > 0 && selectedServiceType === gig.gig_service_types[0] ? 'color:#2d3640;border-color:#2d3640;margin-top: 0': selectedServiceType ? 'color:#2d3640;border-color:#00a575;margin-top: 0' : 'margin-top: 0'">
              <option :value="null" disabled style="color: #8b95a1">Seçin...</option>
              <option :value="option.id" v-for="option in selectedSubCat.category_service_types">{{option.name}}</option>
            </select>
          </div>
        </div>


        <div v-if="showMetaOptions"  class="sub-cat-type-kind-container">
          <p class="title">İş İlanı Temel Bilgiler</p>
          <div style="display: flex; align-items: flex-start; margin-top: 10px">
            <img style="margin-top: 7px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
            <p class="help-text" >Alıcıların seni <span>daha kolay bulması</span> için alttaki bilgileri dikkatle doldurmalısın.</p>
          </div>


          <div class="meta-options-box">
            <div class="meta-options-right">
              <div v-if="rerender && !meta_option.dontShow" @click="selectMetaOptionBox(mindex)" v-for="(meta_option, mindex) in computedMetaOptions"  :key="mindex" :class="meta_option.selected ? 'meta-options-right-row-selected' : 'meta-options-right-row' " >
                <div class="meta-options-right-row-text" :style="isMetaOptionsSelected(mindex) ? 'color:#5e6b79' : 'color: #fe5b5c;'">
                  <p>{{ meta_option.title_for_seller }} {{isMetaOptionsSelected(mindex) ? '' : '*'}}</p>
                  <div class="check-icon-container" v-show="isMetaOptionsSelected(mindex)">
                    <img class="check-icon"
                         src="https://gcdn.bionluk.com/site/cicons/ic-check.svg"
                         onload="SVGInject(this)"/>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="rerender" class="meta-options-left">

              <p class="option-left-title">{{selectedMetaOption.description_for_seller}} <span style="font-weight: 500">{{selectedMetaOption && selectedMetaOption.info && selectedMetaOption.info.max ? '(en fazla '+selectedMetaOption.info.max +' seçenek)' : ''}}</span></p>
              <div v-if="selectedMetaOption && selectedMetaOption.info" class="meta-options-left-form">
                <template v-if="selectedMetaOption.info.type === 'radio'">
                  <div @click="selectMetaOption(oindex)" v-for="(option, oindex) in selectedMetaOption.options" class="filter-radio-item">
                    <div :class="option.selected ? 'filter-radio-full' : 'filter-radio-empty'"></div>
                    <p :class="option.selected ? 'filter-radio-label-active' : 'filter-radio-label' ">{{option.title_for_seller}}</p>
                  </div>
                </template>
                <template v-if="selectedMetaOption.info.type === 'checkbox'">
                  <div @click="selectMetaOption(oindex)" v-for="(option, oindex) in selectedMetaOption.options"  class="filter-checkbox-item" style="display: flex" >
                    <custom-check
                      :container-style="'width:20px; height:20px;'"
                      :check-mark-style="'width:20px; height:20px; border-radius:4px; border: solid 1px #8b95a1;'"
                      called-from=""
                      :single-gig="{}"
                      :id="option.id + '__' + option.title"
                      :checked="option.selected"
                      :disabled="!option.selected && isMetaOptionsDisabled()"
                      :value="option.selected"
                      @input="selectMetaOption(oindex)"
                    />
                    <div :class="option.selected ? 'filter-checkbox-label-active' : isMetaOptionsDisabled() ? 'filter-checkbox-label-disabled' : 'filter-checkbox-label'">{{option.title_for_seller}}</div>
                  </div>
                </template>
                <template v-if="selectedMetaOption.info.type === 'selectbox'">
                  <div style="margin-top: 30px">
                    <select id="sel" class="super-drop" v-model="dummyMetaOption">
                      <option :value="null" disabled style="color: #8b95a1">Seç</option>
                      <option :value="option.id" v-for="(option, oindex) in selectedMetaOption.options">{{option.title_for_seller}}</option>
                    </select>
                  </div>
                </template>
              </div>



            </div>
          </div>


        </div>


        <div style="display: flex; align-items: flex-start;margin-top: 80px; margin-bottom: 30px;">
          <img style="margin-top: 7px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
          <p class="bulb-help-text"><span style="font-weight: 800;">Lütfen Dikkat:</span> Kategori veya hizmet türünü değiştirirsen, tüm paket fiyatlama bilgilerin sıfırlanır.</p>
        </div>
        <div>
          <div @click="openPricingBox = true" v-show="!openPricingBox" class="pricing-accordion">
            <p>Fiyatlandırma</p>
            <img src="https://gcdn.bionluk.com/site/cicons/ic-arrows-right.svg" onload="SVGInject(this)" class="arror-icon-right">
          </div>
          <div v-show="openPricingBox" class="pricing-accordion-full">
            <div @click="openPricingBox = false" class="pricing-accordion-open">
              <p>Fiyatlandırma</p>
              <img src="https://gcdn.bionluk.com/site/cicons/ic-arrows-down.svg" onload="SVGInject(this)" class="arror-icon-right">
            </div>
            <div class="hiddendiv"></div>
            <div class="hiddendiv20"></div>

            <template v-if="!sectionLoading">
              <div v-if="showPricingComponent" style="background: white; padding: 20px;">
                <pricing :gigPackageItems="gigPackageItems" :gigDefaultExtras="gigDefaultExtras" :extraWordObject="extraWordObject" ref="pricing" :packages="packages" :categorySubId="selectedSubCat.id"></pricing>
              </div>
              <p v-else style="color:#fe5b5c;padding-left: 30px; margin-top: -50px; padding-bottom: 30px;">* Fiyatlandırma bölümünü görebilmek için kategori ve hizmet türü bölümlerini tam olarak doldurman gerekiyor</p>
            </template>
            <div v-else style="margin-top: -50px; margin-bottom: 40px;">
              <loader/>
            </div>

          </div>
        </div>


        <label style="margin-top: 60px; margin-bottom: 10px">Etkileyici bir başlık bul!</label>
        <div style="display: flex; align-items: flex-start;margin-bottom: 30px">
          <img style="margin-top: 7px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
          <p class="bulb-help-text">Başlık "Ben... " ile başlayan bir cümle olmalı ve bir fiille bitmelidir. Başlığın sonunda herhangi bir noktalama işareti kullanmamalısın.</p>
        </div>
        <p class="title-static-text">Ben, </p>
        <input maxlength="60" :style="title && gig.title && title === gig.title ? 'border: solid 1px #2d3640;text-indent:42px;width:833px;' : title ? 'border: solid 1px #00a575;text-indent:42px;width:833px;' : 'width:833px;text-indent:42px;'" v-model="title" class="cuper-input-normal" placeholder="uzman olduğum bir konuda...">
        <div style="color: #8b95a1; font-size: 13px; font-weight: 500;display: flex; justify-content: flex-end; margin-top: 10px;">
          {{title.length}} / <span style="color: #2d3640"> 60</span>
        </div>

        <label style="margin-top: 60px; margin-bottom: 30px;">Yapacağın işin detaylarını minimum 150 karakterle açıkla</label>
        <textarea maxlength="2500" v-model="description" :style="description && gig.description_textarea && gig.description_textarea === description ? 'border: solid 1px #2d3640;  width: 820px; height: 200px;' : description ? 'border: solid 1px #00a575;  width: 820px; height: 200px;' : ' width: 820px; height: 200px;'"  class="c-text-area" placeholder="Minimum 150 karakter olacak şekilde nasıl bir hizmet sunduğunu detaylı olarak belirt. İş ilanını ne kadar net yazarsan, alıcıların dikkatini de o kadar çekmiş olursun."></textarea>
        <div style="color: #8b95a1; font-size: 13px; font-weight: 500;display: flex; justify-content: flex-end; margin-top: 10px;">
          <span :style="description.length < 150 ? 'color:#fd4056' : ''"> {{description.length}} </span> / <span style="color: #2d3640"> 2500</span>
        </div>

        <label style="margin-top: 60px; margin-bottom: 10px;">Siparişe başlaman için gerekenler</label>
        <div style="display: flex; align-items: flex-start;margin-bottom: 30px">
          <img style="margin-top: 7px;" src="https://gcdn.bionluk.com/site/cicons/bulb.svg">
          <p class="bulb-help-text">Bu alana bir sipariş aldığında, siparişe başlamak için ihtiyaç duyduğun bilgileri yazabilirsin. Örneğin; bir çeviri hizmeti veriyorsan, bu alanda alıcıya çevrilmesi gereken metni iletmesini isteyebilirsin.</p>
        </div>
        <textarea maxlength="500" v-model="requirement" :style="requirement && gig.requirement && gig.requirement === requirement ? 'border: solid 1px #2d3640;  width: 820px; height: 150px;' : requirement ? 'border: solid 1px #00a575;  width: 820px; height: 150px;' :  ' width: 820px; height: 150px;'"  class="c-text-area" placeholder="Ödeme sonrasında işe başlamak için alıcıdan ne tür bilgilere veya dosyalara ihtiyacın var? Bu alanda detaylı olarak belirtebilirsin."></textarea>
        <div style="color: #8b95a1; font-size: 13px; font-weight: 500;display: flex; justify-content: flex-end; margin-top: 10px;"> {{requirement.length}} / <span style="color: #2d3640"> 500</span></div>




        <label style="margin-top: 60px;">Vitrin</label>
        <div v-if="files.length === 0" @click="openUploadModal(null, null)" class="upload-box">
          <button style="width: 220px; height: 54px;" class="cuper-black-button">
            <img src="https://gcdn.bionluk.com/site/cicons/ic-round-plus.svg"  onload="SVGInject(this)" class="upload-plus-icon">
            <span style="padding-left: 14px;">Dosya Ekle</span>
          </button>
          <div class="help-text">
            Ekleyebileceğin dosya tipleri: <span>JPG, PNG, .MP4, .MP3</span> <span class="emoji">📸 📽 🎙</span>
          </div>
        </div>

        <div v-if="rerender" class="items" style="margin-top: 30px; display: flex; flex-wrap: wrap; margin-left: -47px">
          <div class="gallery-photos-container" style=" display: flex; flex-wrap: wrap;" >
            <div v-for="(file, fi) in files" class="item" style="margin-left: 47px" :key="file.uuid">
              <div v-if="file.file_type === 'audio'" class="video-sound-icon-container">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-sound.svg" onload="SVGInject(this)" class="video-sound-icon">
              </div>
              <div v-if="file.file_type === 'video'" class="video-sound-icon-container">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-video.svg" onload="SVGInject(this)" class="video-sound-icon">
              </div>
              <img class="item-img" :src="file.cover.image_url_small">
              <div :class="fi === 0 ? 'bottom-cover' : 'bottom'">
                <div v-show="fi === 0" style="display: flex; align-items: center; justify-content: flex-start">
                  <img v-if="fi === 0" src="https://gcdn.bionluk.com/site/cicons/ic-star.svg" onload="SVGInject(this)" class="star-icon">
                  <p class="bottom-text">Vitrin</p>
                </div>
                <div v-show="fi !== 0" style="display: flex; align-items: center; justify-content: flex-start">
                  <div @click="makeCover(fi)" class="make-cover-container">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-star.svg" onload="SVGInject(this)" class="star-icon">
                  </div>
                </div>
                <div style="display: flex;">
                  <div @click="openUploadModal(file, fi)" class="delete-icon-container"><img src="https://gcdn.bionluk.com/site/cicons/ic-edit-penci.svg" onload="SVGInject(this)" class="delete-icon"></div>
                  <div @click="removeUpload(fi)" class="delete-icon-container"><img src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg" onload="SVGInject(this)" class="delete-icon"></div>
                </div>
              </div>

            </div>
          </div>
        </div>


      </div>

    </div>
    <div class="bottom-fixed-div">
      <div class="bottom-centered">
        <div style="display: flex">
          <div style="width: 300px;">
            <p class="step-sub-title">İş İlanını Düzenle</p>
          </div>
          <div v-if="selectedCat.id" style="display: flex; height: 46px; margin-right:20px; align-items: center">
            <div  class="bottom-cat-img-container" :style="'background:'+selectedCat.cat_color">
              <img class="bottom-cat-img" :src="'https://gcdn.bionluk.com/site/cicons/catImg/squares/sm_'+selectedCat.id+'.png'">
            </div>
            <p class="bottom-cat-title">{{selectedCat.name}}</p>
          </div>
          <div v-if="selectedSubCat && selectedSubCat.id" style="cursor:pointer;display:flex; align-items:center;margin-right:20px; font-size:14px; padding-left: 20px; padding-right: 20px; color:#ffffff;height: 46px;border-radius: 5px;background-color: rgba(255, 255, 255, 0.1);">
            <div style="display: flex;justify-content: center;">
              <p >{{selectedSubCat.name}}</p>
            </div>
          </div>
          <div v-if="title" style=" cursor:pointer;display:flex; align-items:center;margin-right:20px; font-size:14px; padding-left: 20px; padding-right: 20px; color:#ffffff;height: 46px;border-radius: 5px;background-color: rgba(255, 255, 255, 0.1);">
            <div style="max-width:164px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">Ben, {{title}}</div>
          </div>
        </div>
        <div @click="nextStep" :class="enableButton ? 'continue-button-active' : 'continue-button'">
          {{serviceLoading ? 'Güncelleniyor...' :'Güncelle'}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import draggable from 'vuedraggable';
  import pricing from '../../../../components/PricingComponent';
  import CustomCheck from "@/pages/components/CustomCheck";

  export default {
    name: "src-pages-body-workstation-gigs-edit-v5",
    components: {
      draggable,pricing, CustomCheck
    },
    data() {
      return {


        initCalled:false,

        serviceLoading:false,
        openPricingBox:false,

        pageLoaded:false,
        buttonLoading:false,
        selectedCatId:null,
        selectedSubCatId:null,
        selectedCat:{},
        selectedSubCat:{},
        selectedServiceKinds:[],
        selectedServiceKind:null, //sadece çeviri için şu an hackk
        selectedServiceType:null, //fiyatlamadan değişirse kullanılıyor

        title:'',
        description:'',
        requirement:'',
        gigStatus:null,


        warning:{displayWarning:false, disapprovals:[]},

        files:[],
        gig:{uuid:null},
        uuid:null,
        priceTypes:[],
        rerender:true,
        showSesDilFilter:false,
        gigPackageItems: [],
        gigDefaultExtras: [],
        extraWordObject: {},
        serviceTypeChanger: false,
        packages: {},

        sectionLoading: false,

        selectedMetaOption:{},
        dummyMetaOption:null,
      }
    },

    methods: {


      isMetaOptionsSelected(i){
        let isSelected = false;
        this.selectedSubCat.meta_options.forEach((o,oi) => {
          o.options.forEach( (oo,ooi) => {
            if(i === oi && oo.selected){
              isSelected = true
            }
          })
        });

        return isSelected;
      },

      isMetaOptionsDisabled(){
        let isDisabled = false;
        const foundIndex = this.selectedSubCat.meta_options.findIndex(option => option.selected)

        if (foundIndex > -1 && this.selectedSubCat.meta_options[foundIndex]?.info?.max){
          let currentOptions = this.selectedSubCat.meta_options[foundIndex].options;
          if(currentOptions){
            let count = 0;
            currentOptions.forEach( (oo,ooi) => {
              if(oo.selected){
                count++;
              }
            })
            if(this.selectedSubCat.meta_options[foundIndex].info.max <= count){
              isDisabled = true;
            }
          }
        }


        return isDisabled;

      },

      selectMetaOptionBox(index){
        this.selectedSubCat.meta_options.forEach((m,i) => {
          if(index === i){
            m.selected = true;
            this.selectedMetaOption = m;
          } else {
            m.selected = false;
          }

        });

        this.rerender = false;
        this.rerender = true;
      },


      selectMetaOption(i=null) {
        let selectedMetaOption = this.selectedMetaOption
        const foundIndex = this.selectedSubCat.meta_options.findIndex(option => option.selected)
        let currentOptions = this.selectedSubCat.meta_options[foundIndex].options

        let count = 0;
        currentOptions.forEach(co => {
          if(co.selected){
            count++;
          }
        });
        currentOptions.forEach((o,oi) => {
          if(selectedMetaOption?.info.type === 'radio'){
            o.selected = i === oi;
          } else if(selectedMetaOption?.info.type === 'checkbox'){
            if(i === oi){
              if(selectedMetaOption?.info?.max){
                if(!o.selected){
                  if(selectedMetaOption.info.max > count){
                    o.selected = true;
                  }
                } else {
                  o.selected = false;
                }
              } else {
                o.selected = !o.selected;
              }
            }
          } else if(selectedMetaOption?.info.type === 'selectbox'){
            o.selected = o.id === i;
          }
        });
        this.rerender = false;
        this.rerender = true;
      },

      deleteGig(){
        if(this.buttonLoading) return false;
        if (confirm(this.Constants.TEXTS.GIGS_DELETE_WARNING_DESC) === true) {
          this.buttonLoading = true;
          this.api.seller.changeGigStatus(this.gig.uuid, -1, this.$Progress)
            .then(({data}) => {
              let result = data;
              if (result.success) {
                this.$router.push('/panel/ilanlar');
              } else {
                this.buttonLoading = false;
                this.$toasted.global.errorToast({description: result.message});
              }
            })
            .catch(err => {
              this.buttonLoading = false;
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            });
        }

      },

      async initPricing(){
        this.buttonLoading = true;
        this.sectionLoading = true;
        let a1 = true;
        if(a1){
          await this.getGigPackageItems();
          await this.getGigDefaultExtras();
         // await this.getGigPackages();
        } else {
          if(this.tempStep){
            this.step = this.tempStep;
            this.tempStep = null;
          }
        }
        this.buttonLoading = false;
        this.sectionLoading = false;
      },

      async mutatePricingDataForRequest(data) {
        this.buttonLoading = true;

        const basic = {
          items: []
        };

        const standard = {
          items: []
        };

        const premium = {
          items: []
        };

        const extras = {
          customExtras: [],
          defaultExtras: []
        };
        for (let key in data.basic) {
          const id = key.split('_')[1];
          if (!isNaN(id)) {
            const obj = {
              gig_package_item_id: Number(id),
              value: data.basic[key]
            };
            basic.items.push(obj)
          } else {
            basic[id] = data.basic[key];
          }
        }

        for (let key in data.standard) {
          const id = key.split('_')[1];
          if (!isNaN(id)) {
            const obj = {
              gig_package_item_id: Number(id),
              value: data.standard[key]
            };
            standard.items.push(obj)
          } else {
            standard[id] = data.standard[key];
          }
        }

        for (let key in data.premium) {
          const id = key.split('_')[1];
          if (!isNaN(id)) {
            const obj = {
              gig_package_item_id: Number(id),
              value: data.premium[key]
            };
            premium.items.push(obj)
          } else {
            premium[id] = data.premium[key];
          }
        }

        //extra fast delivery
        basic.extra_fast_delivery = {
          ...data.extraFastDelivery.basic,
          selected: data.extraFastDelivery.selected
        };
        standard.extra_fast_delivery = {
          ...data.extraFastDelivery.standard,
          selected: data.extraFastDelivery.selected
        };
        premium.extra_fast_delivery = {
          ...data.extraFastDelivery.premium,
          selected: data.extraFastDelivery.selected
        };

        //extra words
        basic.extra_words = {
          ...data.extraWords.basic,
          selected: data.extraWords.selected
        };
        standard.extra_words = {
          ...data.extraWords.standard,
          selected: data.extraWords.selected
        };
        premium.extra_words = {
          ...data.extraWords.premium,
          selected: data.extraWords.selected
        };

        data.customExtraGig.forEach(c => {
          extras.customExtras.push(c);
        });

        for (let key in data.extras) {
          const id = key.split('_')[1];
          if (!isNaN(id)) {
            const obj = {
              gig_default_extra_id: Number(id),
              price: data.extras[key].price,
              selected: data.extras[key].selected,
              duration: data.extras[key].duration,
              value: data.extras[key].value,
            };
            extras.defaultExtras.push(obj)
          } else if(id === 'revision') {
            extras.revisionExtra = {
              price: data.extras[key].price,
              selected: data.extras[key].selected,
              duration: data.extras[key].duration
            };
          }
        }

        let a1 = await this.gigUpdateS1();

        if (a1) {
          const requestObject = {
            uuid: this.uuid,
            packageCount: data.enable3Packages ? 3 : 1,
            basic: JSON.stringify(basic),
            standard: JSON.stringify(standard),
            premium: JSON.stringify(premium),
            extras: JSON.stringify(extras),
            voiceoverOptions: JSON.stringify(data.voiceoverOptions)
          }
          this.api.seller.gigUpdateS2_V2(requestObject, this.$Progress)
            .then(({data}) => {
              let result = data;
              if (result.success) {
                this.gigPublish();
              } else {
                this.$toasted.global.errorToast({description: result.message});
                this.buttonLoading = false;
              }

            })
            .catch(err => {
              this.buttonLoading = false;
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            });
        }

      },

      async gigUpdateS1() {
        this.buttonLoading = true;

        let selectedMetaOptions = [];
        this.selectedSubCat.meta_options.forEach(o => {
          let selectedOptions = [];
          let hasIt = false;
          o.options.forEach(oo => {
            if(oo.selected){
              hasIt = true;
              selectedOptions.push(oo.id)
            }
          })
          if(hasIt){
            selectedMetaOptions.push({
              id:o.id,
              options:selectedOptions
            })
          }
        });

        let gigInfo = {
          uuid: this.uuid,
          category_id: this.selectedCat.id,
          category_sub_id: this.selectedSubCat.id,
          title: this.title,
          gig_service_types: this.selectedServiceType,
          gig_service_kinds: this.selectedServiceKinds,
          gig_meta_options: JSON.stringify(selectedMetaOptions)
        };

        return this.api.seller.gigUpdateS1({...gigInfo}, this.$Progress)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.buttonLoading = false;
              return true;
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.buttonLoading = false;
              return false;
            }
          })
          .catch(() => {
            this.buttonLoading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      },


      sesDilTemizle(){
        this.showSesDilFilter = false;
        this.selectedServiceKinds = [];
        this.selectedServiceKind = null;
        this.showSesDilFilter = true;
      },
      sesDilUygula(){
        this.showSesDilFilter = false;
        this.showSesDilFilter = true;
      },
      selectMultiKind(kind){
        this.showSesDilFilter = false;
        let foundIndex = this.selectedSubCat.category_service_kinds.options.findIndex(option => option.id === kind.id)
        if (foundIndex > -1) {
          let arr = this.selectedSubCat.category_service_kinds;
          arr.options[foundIndex].selected = !this.selectedSubCat.category_service_kinds.options[foundIndex].selected;
          this.selectedSubCat.category_service_kinds = arr;
        }
        this.showSesDilFilter = true;
      },

      handleFocusOut(what='gender') {
        if(what === 'dil') {
          this.showSesDilFilter = false;
        }
      },
      showSesGender() {
        this.showSesDilFilter = true;
        this.$nextTick(() => {
          document.getElementById("ses-dil-container").focus()
        })
      },
      removeUpload(index) {
        this.files.splice(index,1)
      },
      addPortfolioItem(payload){
        if(payload.calledFrom && payload.calledFrom === 'edit'){
          let fileIndex = payload.fileIndex;
          let tempFiles = this.files;
          let tempFile = payload.file;
          tempFiles[fileIndex] = tempFile;
          this.files =  JSON.parse(JSON.stringify(tempFiles));
        } else {
          this.files = this.files.concat(payload.file);
        }
      },

      openUploadModal(sendFile=null, fileIndex=null){
        if(!sendFile) sendFile = {upload_id:null};
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.UPLOAD_GALERY, info: {from:'gig',sendFile, fileIndex}});
      },

       nextStep(){
        if(!this.enableButton) return false;
        if(this.buttonLoading) return false;

        this.EventBus.$emit('pricingRequestData', 'sendInfo');


      },


      async gigPublish(){

        let postedFiles = [];
        this.files.forEach(function (file) {

          postedFiles.push({
            upload_id:file.upload_id,
            cover_id:file.cover.upload_id
          });
        });
        let updatedPortfolio = {
          title:this.title,
          description:this.description,
          requirement:this.requirement,
          uuid:this.uuid,
          category_id: this.selectedCat.id,
          category_sub_id: this.selectedSubCat.id,
          gig_service_types: this.selectedServiceType,
          gig_service_kinds: this.selectedServiceKinds,
          upload_id:this.files[0] ? this.files[0].upload_id : null,
          cover_id:this.files[0] ? this.files[0].cover.upload_id : null,
          upload_info: JSON.stringify({items:postedFiles})
        }




        this.serviceLoading = true;
        let a1 = true;
        let a2 = null;
        let a3 = null;
        let a4 = null;
        if(a1) {  a2 = await this.gigUpdateS3();} else {this.serviceLoading = false;}
        if(a2) {  a3 = await this.gigUpdateS4();} else {this.serviceLoading = false;}
        if(a3) {  a4 = await this.gigUpdateCovers();} else {this.serviceLoading = false;}



        if(a4){
          this.buttonLoading = true;
          this.api.seller.changeGigStatus(this.uuid, 1, this.$Progress, true)
            .then(({data}) => {
              let result = data;
              if (result.success) {

                this.$toasted.global.infoToast({description: result.message});
                this.$router.push(result.data.redirect_url);
                this.buttonLoading = false;
                this.serviceLoading = false;
              } else {
                this.$toasted.global.errorToast({description: result.message});
                this.buttonLoading = false;
                this.serviceLoading = false;
              }
            })
            .catch(err => {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
              this.serviceLoading = false;
              this.buttonLoading = false;
            });
        } else {
          this.serviceLoading = false;
        }

      },


      async gigUpdateCovers(){
        this.buttonLoading = true;
        let ret = await this.api.seller.gigUpdateContent(this.uuid, this.files[0] ? this.files[0].upload_id : null, this.files[0] ? this.files[0].cover.upload_id : null, this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.buttonLoading = false;
              return true;
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.buttonLoading = false;
              return false;
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.buttonLoading = false;
            return false;
          });

        return ret;
      },


      async gigUpdateS3() {
       let ret = await this.api.seller.gigUpdateS3(this.uuid, this.description, this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              return true;
            } else {
              this.$toasted.global.errorToast({description: result.message});
              return false;
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            return false;
          });

       return ret;

      },

      async gigUpdateS4() {
        this.buttonLoading = true;
        let ret = await this.api.seller.gigUpdateS4(this.uuid, this.requirement, this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.buttonLoading = false;
              return true;
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.buttonLoading = false;
              return false;
            }

          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.buttonLoading = false;
            return false;
          });
        return ret;
      },


      selectCat(cat){
        this.rerender = false;
        this.selectedSubCatId = null;
        this.selectedSubCat = {id:null, category_service_types:[], meta_options:[], category_service_kinds:{options:[]}};
        this.selectedServiceKinds = [];
        this.selectedServiceKind = null;
        this.selectedServiceType = null;
        this.serviceTypeChanger = false;
        this.selectedCat = cat;
        this.rerender = true;
      },




      selectServiceType(id){
        this.selectedServiceType = id;
        this.serviceTypeChanger = false;
      },

      selectServiceKind(id){
        this.rerender = false;
        if(this.selectedServiceKinds.indexOf(id) > -1){
          this.selectedServiceKinds.splice(this.selectedServiceKinds.indexOf(id),1);

        } else {
          this.selectedServiceKinds.push(id);

        }
        this.rerender = true;
      },

      getGigPackages() {
        this.api.seller.getGig(this.uuid, null, 'gigEdit')
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.$store.state.indexHTMLTitle = result.data.meta.title;
              this.$store.state.indexHTMLMetaDescription = result.data.meta.description;
              this.packages = {...result.data.packages};
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      async getGigPackageItems() {

        const {data} = await this.api.seller.getGigPackageItems(this.selectedSubCat ? this.selectedSubCat.id : null, this.selectedServiceType, this.$Progress);
        const result = data;
        if (result.success) {
          this.gigPackageItems = [...result.data.gig_package_items];
        } else {
          this.$toasted.global.errorToast({description: result.message});
        }
      },

      async getGigDefaultExtras() {
        const {data} = await this.api.seller.getGigDefaultExtras(this.selectedSubCat ? this.selectedSubCat.id : null, this.selectedServiceType, this.$Progress);
        const result = data;
        if (result.success) {
          this.gigDefaultExtras = [...result.data.gig_default_extras];
          this.extraWordObject = result.data.extra_words;
        } else {
          this.$toasted.global.errorToast({description: result.message});
        }
      },

      getGigPriceTypes() {
        this.buttonLoading = true;
        this.api.seller.getGigPriceTypes()
          .then(({data}) => {
            let result = data;
            this.buttonLoading = false;

            if (result.success) {
              this.priceTypes = result.data.gig_price_types;
            } else {
              this.$router.push(result.redirect_url);
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },



      getGigDetail() {
        this.uuid = this.$store.state.routerParams[0];
        this.pageLoaded = false;

        this.getGigPriceTypes();

        this.api.seller.getGig(this.uuid, null, 'gigEdit')
          .then(async ({data}) => {
            let result = data;
            this.pageLoaded = true;
            if (result.success) {
              this.$store.state.indexHTMLTitle = result.data.meta.title;
              this.$store.state.indexHTMLMetaDescription = result.data.meta.description;

              if(result.data.isFeatured){
                const modalObj = {
                  title: 'Dikkat! ⚠️',
                  bodyText:
                    `Bu ilanın  <span style="font-weight: bold">öne çıkan ilanlar</span> arasında listeleniyor. İlanında herhangi bir güncelleme yaparsan, ilanın tüm listeleme sayfalarından geçici olarak kaldırılacak. Bu işlemin tekrar geri alınması mümkün olmuyor ve ilanın öne çıkanlar arasında yeniden listelenmiyor. Devam etmek istiyor musun?`,
                  actionButton: {
                    show: true,
                    title: 'Anladım, devam et',
                    url: '',
                    eventName: 'close'
                  }

                };
                this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.WARNING_MODAL, info: {...modalObj}});

              }


              this.title = result.data.title;
              this.description = result.data.description_textarea;
              this.requirement = result.data.requirement;
              this.gigStatus = parseInt(result.data.status);


              this.warning = result.data.warning;


              this.selectedCat = await this.findCategoryByID(result.data.gig_category.id)
              let subCat = await this.findCategoryByID(result.data.gig_category_sub.id)
              if (subCat.id === 61) {
                subCat.category_service_types = []
              }

              if(result.data.gig_service_types.length){
                this.selectedServiceType = result.data.gig_service_types[0];
              }
              if(result.data.gig_service_kinds.length){
                this.selectedServiceKinds = JSON.parse(JSON.stringify(result.data.gig_service_kinds));
                this.selectedServiceKind = result.data.gig_service_kinds[0];
              }

              let isSelectedMetaOption = false;
              subCat.meta_options.forEach((o,oi) => {
                if(!isSelectedMetaOption){
                  if(o.info?.excluded_service_types?.length && o.info?.excluded_service_types.indexOf(this.selectedServiceType) > -1){
                    o.selected = false;
                  } else {
                    this.selectedMetaOption = o;
                    o.selected = true;
                    isSelectedMetaOption = true;
                  }
                } else {
                  o.selected = false;
                }

                o.options.forEach( (oo,ooi) => {
                  let sel = false;
                  result.data.gig_meta_options.forEach((go, goi) => {
                    if(o.id === go.id){
                      go.options.forEach((goo, gooi) => {
                        if(goo === oo.id){
                          sel = true;

                          if(o.info?.type === 'selectbox'){
                            this.dummyMetaOption = oo.id;

                          }
                        }
                      })
                    }
                  })

                  oo.selected = sel;
                })
              });



              this.selectedSubCat = subCat;
              this.rerender = false;
              this.rerender = true;

              this.selectedCatId = result.data.gig_category.id;
              this.selectedSubCatId = result.data.gig_category_sub.id;



              if(result.data.content && result.data.content.upload_id){
                this.files.push(result.data.content);
              }

              window.scrollTo(0, 0);
              await this.getGigPackageItems();
              await this.getGigDefaultExtras();
              this.packages = result.data.packages;
              if(this.gig.uuid === null){
                this.gig = result.data;
              }





              this.initCalled = true;
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            console.log(err);
            this.pageLoaded = true;
            this.$toasted.global.errorToast({description: err});
          });
      },
    },

    computed: {
      computedMetaOptions: function (){
        if(this.selectedSubCat?.meta_options?.length > 0){
          if(this.selectedSubCat?.category_service_types?.length > 0 && this.selectedServiceType ){
            this.selectedSubCat.meta_options.forEach((mm,ii) =>{
              mm.dontShow = false
              if(mm.info.excluded_service_types?.length && mm.info.excluded_service_types.indexOf(this.selectedServiceType) > -1){
                mm.dontShow = true
                mm.selected = false;
              }
            })
          }
          this.rerender = false;
          this.rerender = true;
          return this.selectedSubCat.meta_options
        } else {
          return [];
        }
      },
      showMetaOptions(){
        if(this.selectedCat && this.selectedCat.id && this.selectedSubCat && this.selectedSubCat.id){
          return (this.computedMetaOptions && this.computedMetaOptions.length)
            && (this.selectedServiceType || (this.selectedSubCat.category_service_types.length === 0 ) )
        } else {
          return false;
        }
      },
      showStep2TypeArea(){
        if(this.selectedCat && this.selectedCat.id && this.selectedSubCat && this.selectedSubCat.id){
          return this.selectedSubCat.category_service_types && this.selectedSubCat.category_service_types.length > 0;
        } else {
          return false;
        }
      },
      enableButton: function(){
        let ret = false;
        let p = this.rerender;
        if(this.buttonLoading || this.serviceLoading){
          ret = false;
        } else {
          if(!this.title || !this.description || !this.requirement || !this.selectedCat.id || !this.selectedSubCat.id ){
            ret = false;
          } else {
            if(this.showStep2TypeArea || this.showMetaOptions){
              let ret_type = true;
              let ret_meta = true;
              if(this.selectedSubCat.category_service_types.length > 0){
                ret_type = false;
                if(this.selectedServiceType){
                  ret_type = true;
                }
              }

              if(this.selectedSubCat.meta_options && this.selectedSubCat.meta_options.length > 0){

                this.selectedSubCat.meta_options.forEach((o, i) => {
                  o.hasSelectedOption = false;
                  if(o.info?.excluded_service_types?.length > 0 && o.info?.excluded_service_types.indexOf(this.selectedServiceType) > -1){
                    o.hasSelectedOption = true;
                  } else {
                    o.options.forEach((oo) => {
                      if(oo.selected){
                        o.hasSelectedOption = true;
                      }
                    })
                  }
                });

                this.selectedSubCat.meta_options.forEach((k, ki) => {
                  if(!k.hasSelectedOption){
                    ret_meta = false;
                  }
                });
              }
              if(ret_meta && ret_type){
                ret = true;
              }

            } else {
              ret = true;
            }
          }

        }

        return ret;
      },


      showPricingComponent: function (){
        if(this.selectedCat && this.selectedCat.id && this.selectedSubCat && this.selectedSubCat.id){
          if(this.showStep2TypeArea && !this.selectedServiceType){
            return false;
          } else {
            return true
          }
        } else {
          return false;
        }
      }

    },

    watch: {
      dummyMetaOption(newVal) {
        let selectedMetaOption = this.selectedMetaOption;
        if(newVal){
          if(selectedMetaOption?.info?.type === 'selectbox'){
            this.selectMetaOption(newVal)
          }
        }
      },
      selectedServiceType(newVal) {
        if(newVal){
          this.packages = {};
          if(this.initCalled ){

            this.initPricing();
            this.openPricingBox = true;
            let isSelectedMetaOption = false;
            this.selectedSubCat.meta_options.forEach((o,oi) => {
              if(!isSelectedMetaOption){
                if(o.info?.excluded_service_types?.length && o.info?.excluded_service_types.indexOf(this.selectedServiceType) > -1){
                  o.selected = false;
                } else {
                  this.selectedMetaOption = o;
                  o.selected = true;
                  isSelectedMetaOption = true;
                }
              } else {
                o.selected = false;
              }

              o.options.forEach( (oo,ooi) => {
                oo.selected = false;
              })
            });
          }

          this.rerender = false;
          this.rerender = true;
        } else {
          this.packages = {};
          this.rerender = false;
          this.rerender = true;
        }
      },
      async selectedCatId(newVal,oldVal) {
        if (newVal && oldVal) {
          this.selectCat( await this.findCategoryByID(newVal))
        }
        if (this.initCalled ) {
          this.openPricingBox = true
        }
      },
      async selectedSubCatId(newVal, oldVal) {
        if(newVal){

          if (this.initCalled ) {
            this.dummyMetaOption = null
            let subCat = await this.findCategoryByID(newVal)
            if (subCat.id === 61) {
              subCat.category_service_types = []
            }
            if(subCat.meta_options && subCat.meta_options && subCat.meta_options.length > 0){
              subCat.meta_options.forEach((c,i) => {
                c.options.forEach((m) => {
                  m.selected = false;
                })
                if(i===0){
                  this.selectedMetaOption = c;
                  c.selected = true;
                } else {
                  c.selected = false;
                }
              });

              this.rerender = false;
              this.rerender = true;
            }
            this.selectedSubCat = subCat;
            this.initPricing();
            this.openPricingBox = true;
          }


          if(oldVal){
            this.selectedServiceKinds = [];
            this.selectedServiceKind = null;
            this.selectedServiceType = null;
            this.serviceTypeChanger = false;
          }
        }
      }
    },
    async created() {
      await this.$store.state.categoriesPromise
      this.EventBus.$on('addPortfolioItem', payload => {
        this.addPortfolioItem(payload);
      });

      this.EventBus.$on('pricingResponseData', payload => {
        if (payload) {
          const data = JSON.parse(JSON.stringify(payload))
          this.mutatePricingDataForRequest(data);
        }
      });

      this.getGigDetail();
    },

    beforeDestroy() {
      this.EventBus.$off("addPortfolioItem");
      this.EventBus.$off("pricingResponseData");

    }
  }
</script>

<style scoped lang="scss">


.finance-info{
  width: 843px;
  height: 100px;
  border-radius: 10px;
  border: solid 1px rgba(242, 153, 51, 0.3);
  background-color: rgba(242, 153, 51, 0.2);
  margin-top: 30px;
  display: flex;
  align-items: center;
  position: relative;

  .disapproval-row{
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    color: #2d3640;
    .link{
      font-weight: 600;
      color: #2D3640;
      text-decoration: underline;
    }
  }

  &.for-seller {
    border: solid 1px rgba(90, 38, 164, 0.3);
    background-color: rgba(90, 38, 164, 0.2);
  }
  .for-seller-icon /deep/{
    width: 36px;
    height: 36px;
    path{
      fill: #5a26a4;
    }
  }
  .for-buyer-icon /deep/{
    width: 36px;
    height: 36px;
    path{
      fill: #f29933;
    }
  }
  .delete-icon /deep/{
    width: 16px;
    height: 16px;
    path{
      fill: #2d3640;
    }
  }
  .title{
    font-size: 16px;
    font-weight: 500;
    color: #343d4a;
    padding-bottom: 4px;
  }
  .description{
    width: 668px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.29;
    color: #4f596c;
  }
}

.filter-radio-item{
  display: flex; align-items: center; cursor: pointer;
  margin-bottom: 20px;

  .filter-radio-empty{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: solid 1px #8b95a1;
  }

  .filter-radio-label{
    font-size: 16px;  color: #5e6b79; margin-left: 8px; line-height: 1.4;

  }

  .filter-radio-label-active{
    font-size: 16px; color: #5e6b79; font-weight: 400; margin-left: 8px; line-height: 1.4
  }

  .filter-radio-full{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: solid 3px #00a575;
  }


}


.filter-checkbox-item{
  cursor: pointer;
  display: flex;
  width: 50%;
  margin-bottom: 24px;


  .filter-checkbox-disabled{
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: solid 1px #8b95a1;
    opacity: 0.4;
  }

  .filter-checkbox-label-disabled{
    padding-left: 10px;
    font-size: 15px;
    opacity: 0.55;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #2D3640;

  }

  .filter-checkbox-empty{
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: solid 1px #8b95a1;
  }

  .filter-checkbox-full{
    width: 12px;
    height: 12px;
    border-radius: 5px;
    border: solid 3px #00a575;

  }

  .filter-checkbox-label{
    padding-left: 10px;
    font-size: 15px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #5e6b79;

  }

  .filter-checkbox-label-active{
    padding-left: 10px;
    font-size: 15px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #5e6b79;

  }

}


.meta-options-box{
  display:flex;border-radius: 5px;
  border: solid 1px #bfc8d2;
  margin-top:30px;
  background-color: #fff; min-height: 250px; width: 870px;

  .meta-options-right{
    flex:2;
    background-color: #f3f5f7;
    border-top-left-radius: 4px;
    border-right:solid 1px #bfc8d2;
  }

  .meta-options-right-row-selected{
    background-color: white; border-top-left-radius: 4px;
    width: 101%; height: 60px; display: flex;
    align-items: center; border-bottom:solid 1px #bfc8d2;
  }

  .meta-options-right-row{
    border-top-left-radius: 4px; width: 100%; height: 60px;
    display: flex; align-items: center; border-right:solid 1px #bfc8d2;
    cursor: pointer;
    &:hover{
      opacity: 0.8;
    }
  }

  .meta-options-right-row-text{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 18px;font-weight: 500;

  }

  .check-icon-container{
    .check-icon /deep/ {
      width: 18px;
      height: 18px;
      path {
        fill: #00a575;
      }
    }
  }

  .meta-options-left{
    flex:5;
    .option-left-title{
      font-weight: 600;
      font-size: 15px;
      padding-top: 20px;
      padding-left: 30px;
    }

    .meta-options-left-form{
      margin-left: 30px;
      margin-top: 30px;
    }
  }

}


  .delete-gig{
    font-size: 17px;
    font-weight: 500;
    margin-top: 25px; margin-right: 30px; color:#fd4056; cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }

  .request-warning{

    width: 843px;
    margin-top: 35px;
    margin-bottom: 35px;
    border-radius: 10px;
    border: solid 1px rgba(242, 153, 51, 0.3);
    background-color: rgba(242, 153, 51, 0.2);

    .request-warning-inside{
      display: flex;
      padding: 20px 30px;
    }

    .warning-head{
      font-size: 16px;
      font-weight: normal;

      color: #2d3640;
    }
    .warning-sub-head{
      margin-bottom: 20px;
      margin-top: 5px;
      font-size: 14px;
      font-weight: 300;

      line-height: 1.14;

      color: #2d3640;
    }


    .warning-icon /deep/ {
      width: 35px;
      height: 32px;
      path {
        fill: #f29933;
      }
    }


    .disapproval-row{
      margin-top: 10px;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      color: #2d3640;
      .link{
        font-weight: bold;
        color: #fd4056;
      }
    }

  }


  .ses-dil-container{
    outline: 0;
    position: absolute;
    z-index: 999;
    width: 305px;
    height: 296px;
    object-fit: contain;
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgba(45, 54, 64, 0.15);
    border: solid 1px #eff3f8;
    background-color: #ffffff;
    margin-top:2px;

    .ses-dil-temizle{
      cursor: pointer;
      color: #2d3640; font-size: 14px;
      &:hover{
        text-decoration: underline;
      }
    }

    .ses-dil-uygula{
      cursor: pointer;
      border-radius: 2px;
      border: none;
      background-color: #2d3640;
      padding: 3px auto 5px;
      color: #ffffff;
      letter-spacing: -0.3px; font-weight: 600; font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 80px;
      height: 28px;
    }

    .ses-dil-checkbox-item{
      cursor: pointer;

      display: flex;
      width: 50%;
      margin-bottom: 33px;

      .ses-dil-checkbox-empty{
        width: 20px;
        height: 20px;
        border-radius: 5px;
        border: solid 1px #8b95a1;
      }

      .ses-dil-checkbox-full{
        width: 14px;
        height: 14px;
        border-radius: 5px;
        border: solid 4px #00a575;
      }

      .ses-dil-checkbox-label-empty{
        padding-left: 5px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #5e6b79;
      }

    }
  }

  .arror-icon-right /deep/ {
    width: 24px;
    height: 24px;
    margin-right: 35px;
    path {
      fill: #5e6b79;
    }
  }


  .pricing-accordion{
    cursor: pointer;
     width: 843px;
    height: 84px;
    border-radius: 5px;
    border: solid 1px #2d3640;
    background-color: #ffffff; padding-left: 30px;
    display: flex; align-items: center;
    justify-content: space-between;
    font-size: 18px; color: #2D3640; font-weight: 400;

    &:hover{
      opacity: 0.9;
    }
  }

  .pricing-accordion-full{

     width: 873px;
    height:auto;
    border-radius: 5px;

    border: solid 1px #bfc8d2;
    background-color: #ffffff;


    &:hover{
      opacity: 0.9;
    }
  }

  .pricing-accordion-open{
    cursor: pointer;
     width: 843px;
    height: 84px; border-radius: 5px;

    background-color: #ffffff; padding-left: 30px;
    display: flex; align-items: center;
    justify-content: space-between;
    font-size: 20px; color: #5e6b79; font-weight: 600;

    &:hover{
      opacity: 0.9;
    }
  }


  .c-text-area{
    border-radius: 5px!important;
    &:focus{
      border: 1px solid #2d3640!important;
    }
  }

  .cuper-input-normal{
    border-radius: 5px!important;
    &:focus{
      border: 1px solid #2d3640!important;
    }
  }
  .title-static-text{
    position: absolute;
    color: #2d3640;
    font-size: 18px;
    margin-top: 30px;
    margin-left: 25px;
  }
  div{
    scroll-behavior: smooth;
  }

  .bulb-help-text{
    font-size: 16px;
    font-weight: normal;
    line-height: 1.63;
    color: #5e6b79;
    margin-left: 5px;
    max-width: 824px;
  }


  .items{

    .item{
      position: relative;
      width: 250px;
      height: 181px;
      line-height: 0;
      margin-bottom: 30px;
      .item-img{
        width: 250px;
        height: 141px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      .bottom{
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        background-color: #5e6b79;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .bottom-cover{
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        background-color: #fd4056;
      }

      .video-sound-icon-container{
        width: 40px;
        height: 40px;
        border-radius: 5px;
        background-color: rgba(45, 54, 64, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top:10px;
        left:10px;
        .video-sound-icon /deep/ {
          width: 22px;
          height: 22px;
          path {
            fill: #fff;
          }
        }
      }


      .delete-icon-container{
        margin-right: 10px;
        width: 26px;
        height: 26px;
        border-radius: 5px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .delete-icon /deep/ {
          width: 16px;
          height: 16px;
          path {
            fill: #2d3640;
          }
        }
        &:hover{
          opacity: 0.8;
        }
      }

      .bottom-text{
        margin-left: 5px;
        font-size: 12px;
        font-weight: 600;
        color: #eff3f8;
      }

      .star-icon /deep/ {
        margin-left: 15px;
        width: 20px;
        height: 20px;
        path {
          fill: #fff;
        }
      }
    }


    .make-cover-container{
      cursor: pointer;
      &:hover{
        opacity: 0.8;
      }
    }

  }

  .upload-box{

    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    width: 843px;
    height: 172px;
    border-radius: 10px;
    border: dashed 2px #bfc8d2;
    background-color: rgba(191, 200, 210, 0.1);

    .help-text{
      margin-top: 35px;
      font-size: 14px;
      font-weight: 600;
      color: #2d3640;

      span{
        padding-left: 5px;
        font-size: 14px;
        font-weight: 300;
        color: #8b95a1;
      }

      .emoji{
        padding-left: 20px;
        font-size: 18px;
        font-weight: normal;
        color: #2d3640;
      }
    }

    .upload-plus-icon /deep/ {
      width: 18px;
      height: 18px;
      path {
        fill: #fff;
        &:hover{
          opacity: 0.8;
        }
      }
    }

    &:hover{
      border: dashed 2px #fd4056;
      .cuper-black-button{
        background: #fd4056;
      }
    }
  }



  label {
    font-size: 26px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2d3640;
    margin-bottom: 20px;
  }

  .error {
    border: 1px solid #d0011b !important;
  }


  .step-2-container{
    margin-top: 60px;
    .header-img{
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      width: 843px;
      height: 103px;
      background-repeat: no-repeat;
      background-size: 843px;
      border-radius:10px;
    }

    .step-2-text-container{
      margin-left: 108px;
    }
    .step-2-title{
      font-size: 18px;
      font-weight: 600;

      margin-top: 5px;
    }
    .step-2-sub-title{
      font-size: 16px;
      line-height: 1.25;

      span{
        font-weight: bold;
      }
    }




  }

  .sub-cat-box-container{
    display: flex;
    flex-wrap: wrap;
    width: 843px;
    justify-content: space-between;
    .sub-cat-box{
      cursor: pointer;
      margin-top: 30px;
      width: 393px;
      height: 79px;
      border-radius: 5px;
      border: solid 1px #bfc8d2;
      background-color: #ffffff;
      font-size: 18px;
      color: #8b95a1;
      display: flex;
      align-items: center;
      span{
        padding-left: 25px;
      }
      &:hover{
        border: solid 1px #00a575;
        background-color: #ffffff;
        color: #2d3640;
      }
    }

    .sub-cat-box-active{
      margin-top: 30px;
      width: 393px;
      height: 79px;
      border-radius: 5px;
      border: solid 1px #00a575;
      background-color: rgba(103, 204, 100, 0.1);
      font-size: 18px;
      color: #2d3640;
      display: flex;
      align-items: center;
      span{
        padding-left: 25px;
      }

    }
  }


  .bottom-fixed-div {
    position:fixed; bottom:0; width: 100%; background: linear-gradient(to right, #0e0e0f, #2d3740); height: 100px; z-index:11;
    left: 0;

    .continue-button{
      position: absolute;
      right: 0;
      background: #8b95a1; height: 100px; width: 200px;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.38px;
      text-align: center;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .continue-button-active{
      position: absolute;
      right: 0;
      cursor: pointer;
      -webkit-transition: all 0.18s ease-out;
      -moz-transition: all 0.18s ease-out;
      -ms-transition: all 0.18s ease-out;
      -o-transition: all 0.18s ease-out;
      transition: all 0.18s ease-out;

      height: 100px; width: 200px;
      background-color: #00a575;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.38px;
      text-align: center;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover{
        height: 120px; width: 240px;

      }
    }

    .bottom-cat-img-container{
      cursor: pointer;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      width: 46px;
      height: 46px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      .bottom-cat-img{
        width: 22px;
        height: 22px;
      }
    }

    .bottom-cat-title{
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.13;
      color: #ffffff;
      margin-left: 10px;

    }

    .bottom-centered{
      margin: 0 auto;
      width: 1440px;
      max-width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;


    }
    .step-title{
      font-size: 18px;
      font-weight: 600;
      line-height: 1.44;
      color: #ffffff;
      margin-bottom: 5px;
    }

    .step-sub-title{
      font-size: 24px;
      font-weight: 300;
      letter-spacing: -0.46px;
      color: #ffffff;
    }
  }




  .create-area{
    margin-left: 57px;
    margin-top: 30px;

    .title{
      font-size: 34px;
      color: #2d3640;
      margin-bottom: 14px;
    }
    .sub-title{
      font-size: 18px;
      line-height: 1.44;
      color: #6a7685;
      span{
        font-weight: bold;
        color: #2d3640;
      }
    }

    .main-cats-container{
      margin-top: 60px;
      display: flex;

      flex-wrap: wrap;
      .main-cats{
        margin-bottom: 30px;
      }

      .main-cats-bg{
        cursor: pointer;
        width: 215px; height: 190px;
        background-repeat: no-repeat;
        background-size: 190px 190px;

        .cat-title{
          padding-top: 106px;
          padding-left: 20px;
          padding-bottom: 5px;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.18;

        }
        .cat-sub-title{
          padding-left: 20px;
          opacity: 0.4;
          font-size: 14px;
          font-weight: normal;
          line-height: 1.43;

          span{
            font-weight: bold;
          }
        }
      }

      img{
        width: 190px;
        height: 190px;
      }
    }
  }


  .sub-cat-type-kind-container{
    .title{
      font-size: 26px;
      font-weight: 500;
      color: #2d3640;
      margin-top: 40px;
    }

    .help-text{
      margin-left: 5px;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      color: #5e6b79;
      span{
        font-weight: bold;

      }
    }

  }
</style>
